const en = {
  login: {
    welcomeMessage: 'Hi, Welcome back',
    dontHaveAccount: "Don't have an account? ",
    getStarted: 'Get started',
    header: 'Sign in to Tixa Admin',
    details: 'Enter your details below.',
    email: 'Email',
    password: 'Password',
    staySignedIn: 'Stay signed in',
    forgotPassword: 'Forgot password?',
    login: 'Login',
    errorMsg: {
      required: '{{ fieldNameEn }} is required.',
      validEmail: 'Must be a valid email address.',
    },
  },
  register: {
    welcomeMessage: 'Take control with Tixa',
    haveAccount: 'Already have an account? ',
    signIn: 'Login',
    header: 'Get started free',
    details: 'Sign up easily.',
    firstName: 'Firstname',
    lastName: 'Lastname',
    email: 'Email',
    phoneNumber: 'Phone number',
    password: 'Password',
    confirmPassword: 'Confirm password',
    register: 'Register',
    confirmationText: 'By registering, I agree to Tixa ',
    termsOfService: 'Terms of Service',
    link: 'and',
    privacyPolicy: 'Privacy Policy',
    errorMsg: {
      required: '{{ fieldNameEn }} is required.',
      firstNameLength: 'Firstname must be at least 3 chars.',
      lastNameLength: 'Lastname must be at least 3 chars.',
      validEmail: 'Must be a valid email address.',
      validPhoneNumber: 'Must be a valid phone number.',
      passwordLength: 'Password must be at least 6 chars.',
      passwordMatch: 'Passwords must match.',
    },
  },
  home: {
    content:
      "This page is designed to make it easy for you to create and manage your events. Come on, let's create memorable events!",
    tixaTeam: 'The Tixa team',
  },
  eventNewEdit: {
    newEventTitle: 'New event',
    editEventTitle: 'Edit event',
    breadcrumb: {
      dashboard: 'Dashboard',
      event: 'Event',
      newEvent: 'New event',
      editEvent: 'Edit',
    },
    stepper: {
      basicData: 'Event details',
      location: 'Location',
      ticket: 'Ticket',
      picture: 'Pictures',
      description: 'Description',
      preview: 'Preview',
    },
    basicData: {
      namePlaceholder: 'Event',
      form: {
        name: 'Name *',
        nameEn: 'English name',
        editNameEn: 'Edit english name',
        vatStatus: 'VAT *',
        date: 'Event start {{ required }}',
        doorOpening: 'Doors open',
        eventEnd: 'Event end {{ required }}',
        editSalesDate: 'Edit sales date',
        salesStart: 'Sales start {{ required }}',
        salesEnd: 'Sales end {{ required }}',
      },
      toggleGroup: {
        settings: 'Settings',
        nameEn: 'English name',
        doorOpening: 'Doors open',
        sales: 'Sales',
        vat: 'VAT',
      },
      diffSalesAndEventEndMsg: 'The difference between event end and sales end is more than 24h.',
      diffDateTextMsg:
        'The date displayed on the page has not been updated. You can change it under "Advanced settings" (Preview).',
      uniqueLinkMsg:
        'Your event link must be unique. You can change it under "Advanced settings" (Preview).',
    },
    location: {
      location: 'Location {{ required }}',
      addNewLocation: '+ Add new location',
      form: {
        header: 'New location',
        name: 'Name *',
        addressHeader: 'Address',
        country: 'Country',
        countryHu: 'Magyarország',
        postalCode: 'Postal code *',
        locality: 'City *',
        addressDetails: 'Address *',
        save: 'Save',
        cancel: 'Cancel',
        errorMsg: {
          required: '{{ fieldNameEn }} is required.',
          createFailed: 'Could not create the location',
        },
      },
    },
    ticket: {
      deleteDialog: {
        title: 'Delete confirmation',
        back: 'Cancel',
        confirm: 'Delete',
        ok: 'Ok',
      },
      ticketHelp: {
        ticketHelp: 'How to setup my tickets? <l>Details</l>',
      },
      ticketType: {
        newTicketType: 'New ticket type',
        form: {
          newHeader: 'New ticket type',
          editHeader: 'Edit ticket type',
          name: 'Name',
          enName: 'English name',
          editEnName: 'Edit english name',
          save: 'Save',
          cancel: 'Cancel',
          errorMsg: {
            required: '{{ fieldNameEn }} is required.',
            createTicketTypeFailed: 'Could not create the ticket type',
            updateTicketTypeFailed: 'Could not update the selected ticket type',
          },
        },
        edit: {
          tooltip: 'Edit',
        },
        delete: {
          tooltip: 'Delete',
          deletionAllowed:
            "You want to delete the '{{ ticketTypeName }}' ticket type! Are you sure?",
          deletionDenied: `You cannot delete the last ticket type. You can <1>edit</1> it or <3>create</3> a new one.`,
          deleteTicketTypeFailed: 'Could not delete the selected ticket type',
        },
      },
      offer: {
        newOffer: 'New offer',
        form: {
          newHeader: 'New offer',
          editHeader: 'Edit ticket price',
          name: 'Name',
          namePlaceholder: 'early bird, normál, etc.',
          enName: 'English name',
          enNamePlaceholder: 'early bird, normal, etc.',
          price: 'Price *',
          quantity: 'Quantity *',
          cartLimit: 'Cart limit',
          boxOfficeLimit: 'Cash desk limit',
          editSalesDate: 'Edit sales date',
          salesStart: 'Sales start',
          salesEnd: 'Sales end',
          sold: 'Sold',
          info: 'If you wish to divide an item on the invoice, please, inform your contact person.',
          save: 'Save',
          cancel: 'Cancel',
          errorMsg: {
            required: '{{ fieldNameEn }} is required.',
            minQuantity: 'The quantity cannot be 0.',
            maxQuantity: 'The quantity cannot be more than 100.',
            minCartLimit: 'The cart limit cannot be 0.',
            createOfferFailed: 'Could not create the offer',
            updateOfferFailed: 'Could not update the selected offer',
            salesStartMin:
              'The start of the sale cannot be earlier than the start of the global sales start date {{ salesStartDate }}.',
            salesEndMax:
              'The end of the sale is only possible until the end of the global sales end date {{ salesEndDate }}.',
          },
        },
        edit: 'Edit',
        lock: 'Ask your contact person to unlock the ticket price.',
        unlock: 'The ticket price is automatically closed when it reaches the set limit.',
        delete: {
          tooltip: 'Delete',
          deletionAllowed: "You want to delete the '{{ offerName }}' ticket price. Are you sure?",
          deletionDenied: `You cannot delete the last ticket price. You can <1>edit</1> it or <3>create</3> a new one.`,
          deleteOfferFailed: 'Could not delete the selected offer.',
          deleteSoldOfferFailed: 'Already sold offers cannot be deleted.',
        },
        show: 'Show',
        hide: 'Hide',
        zeroOfferPriceMsg: 'The value of ticket price is zero.',
      },
      totalQty: 'Total:',
    },
    pictures: {
      coverImg: 'Cover image',
      coverImgInfo: 'Ideal image size: 1000x650, max. file size: 8MB, files: JPG, JPEG, PNG',
      gallery: 'Gallery',
      galleryInfo: 'max. 4 pictures, files: JPG, JPEG, PNG',
      ticketImg: 'Ticket header',
      ticketImgTooltip:
        'The 10x3 cm area in the top left corner of the PDF ticket, which you can otionally use to place graphics.',
      ticketImgInfo: 'Ideal size 10x3cm, 150-300 dpi, files: JPG, JPEG, PNG',
      ticketTypeImgs: 'Ticket header - ticket types',
      ticketTypeImgsTooltip:
        'The 10x3 cm area in the top left corner of the PDF ticket, which you can otionally use to place graphics. You can even change it by ticket type, so you can highlight the ticket type name or add specific information related to the ticket type',
      uploadImg: {
        header: 'Upload image',
        description: 'Drop files here or <1>select from your machine</1>',
        timeInfo: 'Uploading image can take several seconds.',
      },
      cropImg: {
        header: 'Image edit',
        crop: 'Edit',
        cancel: 'Cancel',
      },
      generalTicketImg: 'General',
      ticketPreview: {
        tooltip: 'View ticket sample',
      },
      ticketImgDelete: {
        tooltip: 'Delete ticket header',
      },
      errorMsg: {
        'aspect-ratio-error': 'Invalid aspect ratio or quality.',
        'file-too-large': 'Image size is larger than allowed.',
        'file-invalid-type': 'Invalid file type. Only JPG, JEPG or PNG.',
        uploadFailed: 'Failed to upload the image.',
        cropFailed: 'Failed to crop the image.',
        deleteFailed: 'Failed to delete the image.',
        downloadTicketPreviewFailed: 'Failed to download the ticket preview.',
      },
    },
    description: {
      huDescription: {
        header: 'Hungarian description',
        placeholder: 'Hungarian event description...',
      },
      videoLink: 'YouTube link',
      videoLinkPlaceholder: 'Copy the YouTube link here to embed the video',
      seoDescription: 'SEO description',
      seoDescriptionInfo:
        'This is the meta description, the text that appears below the title in search engine results.',
      addEnDescription: 'Edit english description',
      enDescription: {
        header: 'English description',
        placeholder: 'English event description...',
      },
    },
    preview: {
      specialSettings: {
        specialSettings: 'Advanced settings',
        form: {
          header: 'Advanced settings',
          url: 'Link {{ required }}',
          dateWithText: 'Displaying date',
          dateWithTextEn: 'Displaying date in English',
          ntakEventId: 'NTAK event identifier',
          ntakInfo:
            'If you are selling through more than one ticket provider, you will need to enter the same unique identifier on every platform, which will allow NTAK to identify that it is the same event.',
          ageLimit: 'From what age can you participate?',
          ageLimitFree: 'Up to how old is free?',
          age: 'Age',
          secret: 'Secret',
          secretInfo: 'The event is only accessible to those with a link.',
          save: 'Save',
          cancel: 'Cancel',
        },
      },
      gateTime: 'Doors open',
      free: 'Free',
      sold: 'Sold out',
      inactivePrice: 'Inactive price',
      publicSuccessDialog: {
        title: 'Successful publication',
        content: 'You successfully published the event. It is now available on Tixa!',
        ok: 'Ok',
      },
    },
    requiredFields: {
      title: 'Required data for publication',
    },
    backToListBtn: 'Back to the list',
    backToListMobileBtn: 'List',
    backBtn: 'Back',
    nextBtn: 'Next',
    public: 'Publish',
    errorMsg: {
      required: '{{ fieldNameEn }} is required.',
      invalidDate: 'The selected date is invalid.',
      eventStart: 'The event cannot start earlier than today.',
      eventEndPast: 'The end of the event cannot be earlier than today.',
      eventEndMinDate: 'The end of the event cannot be earlier than the start of the event.',
      salesStartMin: 'The start of the sale cannot be earlier than today.',
      salesStartMax:
        'The start of the sale cannot be later than the the global sales end date {{ salesEndDate }}.',
      salesEndPast: 'The end of the sale cannot be earlier than today.',
      salesEndMin: 'The end of the sale cannot be earlier than the start of the sale.',
      salesEndMax: 'The end of the sale cannot be later than the end of the event.',
    },
    snackbarMsg: {
      saveSucceeded: 'Saved successfully!',
      saveFailed: 'Failed to save.',
      preventMultipleEditEvents: 'You are already editing that event in another window.',
      preventMultipleEditEventsByAnotherUser: 'User {{ nameEn }} is currently editing the event.',
      coverImg: 'Failed to load the cover image.',
      gallery: 'Failed to load the gallery.',
      ticketImg: 'Failed to load the ticket image.',
      ticketTypeImgs: 'Failed to load the ticket type images.',
      updateTicketTypeSalesEndDateFailed:
        'Automatic update of ticket prices sales date failed. Check under the "Ticket" section.',
    },
  },
  eventList: {
    title: 'Events',
    breadcrumb: {
      dashboard: 'Dashboard',
      event: 'Event',
      list: 'List',
    },
    newEvent: 'New event',
    table: {
      tabs: {
        active: 'Active',
        archive: 'Archive',
      },
      toolbar: {
        search: 'Search...',
      },
      header: {
        id: 'ID',
        name: 'Name',
        location: 'Location',
        eventStart: 'Event start',
        eventEnd: 'Event end',
        active: 'Status',
      },
      content: {
        statusInfo: {
          draft: 'Draft',
          readyToSale: 'Ready to sale',
          onSale: 'On sale',
          closedSale: 'Closed sale',
        },
      },
      actions: {
        edit: 'Edit event',
        delete: 'Delete event',
        cannotDelete: 'Cannot be deleted, tickets have already been bought for this event.',
      },
      footer: {
        dense: 'Dense',
        rowsPerPage: 'Rows per page',
      },
      empty: 'No Data',
    },
  },
  limitManagement: {
    title: 'Limit management',
    breadcrumb: {
      dashboard: 'Dashboard',
      event: 'Event',
      limit: 'Limit',
    },
    table: {
      toolbar: {
        search: 'Search...',
      },
      header: {
        status: 'Status',
        id: 'ID',
        name: 'Name',
        eventStart: 'Event start',
        salesEnd: 'Sales end',
      },
      content: {
        statusInfo: {
          draft: 'Draft',
          readyToSale: 'Ready to sale',
          onSale: 'On sale',
          closedSale: 'Closed sale',
        },
        confirmInfo: {
          save: 'Save',
          required: 'Limit is required.',
          soldLimit: 'Limit cannot be less than the tickets sold.',
          cancel: 'Cancel',
        },
      },
      footer: {
        openAll: 'Open all',
        closeAll: 'Close all',
        rowsPerPage: 'Rows per page',
      },
      empty: 'No Data',
      ticketTypeTable: {
        header: {
          offer: 'Offer',
          price: 'Price',
          limit: 'Limit',
          sold: 'Sold',
          onSale: 'On sale',
          guestTicket: 'Guest ticket',
        },
        content: {
          boxOffice: 'Box office',
          total: 'Total',
        },
      },
    },
    successMsg: {
      limitUpdateSucceeded: 'Successful save!',
    },
    errorMsg: {
      limitUpdateFailed: 'Could not update the limit.',
    },
  },
  eventDelete: {
    confirmationDialog: {
      title: 'Delete confirmation',
      content: `You want to delete the '{{ eventName }}' event. Are you sure?`,
      confirm: 'Delete',
      back: 'Back',
    },
    errorMsg: {
      eventDeleteFailed: 'Could not delete the selected event.',
      eventDeleteSucceeded: 'Deleted successfully.',
    },
  },
  eventHistory: {
    title: 'History',
    breadcrumb: {
      dashboard: 'Dashboard',
      events: 'Event',
      history: 'History',
    },
    table: {
      toolbar: {
        actionType: 'Action',
        all: 'All',
        search: 'Search...',
        from: 'From',
        to: 'To',
      },
      header: {
        user: 'User',
        action: 'Action',
        event: 'Event',
        created: 'Date',
        status: 'Status',
      },
      statusSuccess: 'Successful operation',
      statusFailed: 'Unsuccessful operation',
      details: 'Details',
      footer: {
        dense: 'Dense',
        rowsPerPage: 'Rows per page',
      },
      empty: 'No Data',
    },
    details: {
      user: 'User:',
      event: 'Event:',
      modified: 'Modified:',
      boxOffice: 'Box office:',
      sections: {
        basicData: '- event details',
        location: '- location',
        ticket: '- ticket',
        pictures: '- pictures',
        description: '- description',
        limit: '- limit',
      },
      info: {
        createEvent: 'Add new event',
        editEvent: 'Edit event',
        deleteEvent: 'Delete event',
        editOffer: 'Edit offer',
      },
      error: 'Something went wrong.',
    },
    actions: {
      createEvent: 'Add new event',
      editEvent: 'Edit event',
      deleteEvent: 'Delete event',
      editOffer: 'Edit offer',
    },
  },
  statistics: {
    sales: {
      report: {
        label: 'Report',
        newReport: 'New report',
        form: {
          email: 'Email',
          schedule: 'Frequency',
          daily: 'Daily',
          weekly: 'Weekly',
          startTime: 'Start date',
          sendingLanguage: 'Sending language',
          language: 'Language',
          save: 'Save',
          edit: 'Edit',
          cancel: 'Cancel',
          saveSuccess: 'Successful save!',
          saveFailed: 'Failed to save data.',
          deleteSuccess: 'Successful delete.',
          deleteFailed: 'Failed to delete data.',
          errorMsg: {
            required: '{{ fieldNameHu }} is required.',
            createReportFailed: 'Could not create report.',
            selectEvent: 'Select at least one event.',
            addEmail: 'Add an email address.',
            validEmail: 'Must be a valid email address.',
            noAccentedChars: 'Accented characters are not allowed.',
            selectSchedule: 'Select a frequency.',
            selectStartTime: 'Select a start time.',
            invalidDate: 'The selected date is invalid',
            minDate: 'The selected date is invalid *',
            minDateInfo: '* The selected date cannot be earlier than today.',
            eventHasReport:
              'If you have already created a report for an event, you can edit it or add a new email address in the list.',
            emailAlreadyAdded: 'Email already added.',
          },
          help: {
            info: 'Send up-to-date ticket sales reports. <l>Details</l>',
            title: 'Details',
            contentP1:
              'Click on the New report button to set up daily or weekly ticket sales reports.',
            contentP2:
              'The email report will always be sent at the time you specify, until the end of the event.',
            contentP3:
              'You can add, edit and delete addresses to the report you have set up and the recipient can unsubscribe.',
            contentP4:
              'The email includes the number of tickets currently sold, broken down by ticket type.',
          },
        },
        listing: {
          title: 'Reports',
          header: {
            reportId: 'Report ID',
            eventId: 'Event ID',
            eventName: 'Event name',
            startTime: 'Start date',
            sendingLanguage: 'Sending language',
            language: 'Language',
          },
          content: {
            schedule: {
              daily: 'daily',
              weekly: 'weekly',
            },
            language: {
              hu: 'Hungarian',
              en: 'English',
            },
            noData: 'You have not created a report, yet.',
          },
          actions: {
            edit: 'Edit',
            delete: 'Delete',
            reportDeletionAllowed: "You want to delete the '{{ report }}' report. Are you sure?",
            emailDeletionAllowed: "You want to delete the '{{ email }}' email. Are you sure?",
          },
          empty: 'No Data',
        },
        unsubscribe: {
          successMsg: 'You unsubscribed successfully!',
          errorMsg: 'Something went wrong. Please, try again later!',
        },
      },
    },
  },
  statisticsSales: {
    title: 'Sales statistics',
    breadcrumb: {
      dashboard: 'Dashboard',
      statistics: 'Statistics',
      sales: 'Sales',
    },
    table: {
      tabs: {
        active: 'Active',
        archive: 'Archive',
      },
      toolbar: {
        search: 'Search...',
        from: 'From',
        to: 'To',
      },
      header: {
        id: 'ID',
        name: 'Name',
        location: 'Location',
        eventStart: 'Event start',
        salesEnd: 'Sales end',
      },
      content: {
        confirmInfo: {
          save: 'Save',
          required: 'Limit is required.',
          soldLimit: 'Limit cannot be less than the sold tickets.',
          cancel: 'Cancel',
        },
        details: 'Detailed statistics',
        noData: 'No data matching the filter.',
      },
      footer: {
        openAll: 'Open all',
        closeAll: 'Close all',
        rowsPerPage: 'Rows per page',
      },
      empty: 'No Data',
      ticketTypeTable: {
        header: {
          offer: 'Offer',
          price: 'Price',
          limit: 'Limit',
          sold: 'Sold',
          revenue: 'Revenue',
          onSale: 'On sale',
          onSaleInfo:
            'All tickets available, On sale numbers are not affected by the  period filter.',
          guestTicket: 'Guest ticket',
        },
        content: {
          boxOffice: 'Box office',
          total: 'Total',
        },
      },
    },
    details: {
      title: 'Detailed statistics',
      sold: 'Sold',
      onSale: 'On sale',
      revenue: 'Revenue',
      guestTicket: 'Guest ticket',
      previousPeriod: 'Previous period',
      summary: {
        ticketType: 'Ticket type',
        offer: 'Offer',
        total: 'Total',
        online: 'Online',
        boxOffice: 'Box office',
        noData: 'No Data',
      },
      pcs: 'pcs',
    },
    successMsg: {
      limitUpdateSucceeded: 'Saved successfully!',
    },
    errorMsg: {
      limitUpdateFailed: 'Could not update the limit.',
    },
  },
  statisticsCustomized: {
    title: 'Customized statistics',
    breadcrumb: {
      dashboard: 'Dashboard',
      statistics: 'Statistics',
      customizedStatistics: 'Customized statistics',
      customizedStatisticsCategory: 'Categories',
    },
    create: 'New customized statistics',
    help: {
      info: 'You can sort your ticket types by the categories you define. <1>Details</>',
      summary:
        'To compile your customized statistics, you can select ticket types from any of your events and group them according to the categories you define. You can also assign multiplying numbers to the ticket types. In the example below, we illustrate the feature with a daily breakdown of a festival, but you can categorize tickets based on any other custom requirement.',
      example: {
        title: 'Example',
        content:
          "With the customized statistics you can compile the number of expected daily visitors for a multi-day event. For a double pass, we can multiply the ticket type by two to get the number of visitors corresponding to the ticket type. If you have a ticket type where the visitor can decide which day they visit the event, you can enter a multiplier of less than 1. For example, if your two-day festival has a daily ticket that can be used on any day, then there is a 50-50% chance that the visitor will enter on the first or second day. So you can use a multiplier of 0.5 to estimate which day's attendance will be boosted by the number of tickets sold.",
      },
      close: 'Close',
    },
    form: {
      titleCreate: 'New customized statistics',
      titleEdit: 'Edit customized statistics',
      name: 'Name',
      englishName: 'English name',
    },
    category: {
      title: 'Customized statistics - categories',
      showGuests: 'Add guest tickets',
      create: 'Category',
      form: {
        titleCreate: 'New category',
        titleEdit: 'Edit category',
        name: 'Name',
        englishName: 'English name',
      },
      item: {
        title: 'Ticket types',
        create: 'Ticket type',
        tooltip: {
          confirmInfo: {
            required: 'Multiplier is required',
            save: 'Save',
            cancel: 'Cancel',
          },
          delete: 'Delete',
        },
      },
      tooltip: {
        edit: 'Edit',
        delete: 'Delete',
      },
    },
    eventList: {
      header: {
        id: 'ID',
        name: 'Name',
        eventStart: 'Event start',
      },
    },
    tooltip: {
      edit: 'Edit',
      delete: 'Delete',
    },
    save: 'Save',
    cancel: 'Cancel',
  },
  marketingCoupon: {
    title: 'Coupon',
    titleStatistics: 'Statistics',
    breadcrumb: {
      dashboard: 'Dashboard',
      marketing: 'Marketing',
      coupon: 'Coupon',
      couponCode: 'Coupon code',
      statistics: 'Statistics',
    },
    newCoupon: 'New coupon',
    table: {
      header: {
        id: 'ID',
        name: 'Name',
        type: 'Type',
        couponCode: 'Coupon code',
        redemption: 'Redemption',
        validFrom: 'Starting',
        validUntil: 'Ending',
      },
      content: {
        couponType: {
          discount: 'Discount',
          fixedPrice: 'Fixed price',
        },
        tooltip: {
          edit: 'Edit',
          couponCodes: 'Coupon codes',
          statistics: 'Statistics',
          details: 'Details',
          delete: 'Delete',
          cannotDelete: 'Cannot be deleted, coupons have already been redeemed.',
        },
      },
      noData: 'No data matching the filter.',
    },
    couponAddForm: {
      steps: {
        type: 'Type',
        data: 'Data',
        redemption: 'Redemption',
      },
      btn: {
        next: 'Next',
        save: 'Save',
        back: 'Back',
        search: 'Search...',
      },
      errors: {
        type: 'Type is required.',
        name: 'Coupon name is required.',
        validFrom: 'Starting date is required.',
        invalidDate: 'The selected date is invalid.',
        validUntil:
          'The end of the redemption period cannot be earlier than the start of the redemption period.',
        discountValue: 'Coupon rate is required.',
        discountMaxValue: 'To maximise the value of the discount, enter a number greater than 0.',
        minimumCartValue: ' To set the minimum cart value, enter a number greater than 0.',
        redemptionLimit: 'To set a limit, enter a number greater than 0.',
        cartValueLimit: 'The cart value cannot be less than the discount value.',
        valueGreaterThan100: 'The discount rate cannot be greater than 100%.',
        couponRestrictions: 'Select at least one event.',
      },
      label: {
        couponInfo: 'Coupon information',
        validEvents: 'Events',
        infoToAllEventsSelect:
          'The coupon will also be valid for drafts, ready-to-sell and ongoing events.',
        saveSuccess: 'Saved successfully!',
        saveFailed: 'Failed to save data.',
        couponHelperText: 'Archive coupon cannot be edited. You find the details below:',
      },
      CouponAddType: {
        fixedPrice: 'Fixed price',
        fixedPriceLabel: 'The fixed price of the cart.',
        discount: 'Discount',
        discountLabel: 'The discount is calculated from the total amount of the cart.',
        tooltip: {
          warn: 'The coupon type cannot be changed after saving.',
        },
      },
      CouponAddData: {
        type: 'Type',
        name: 'Coupon name',
        nameLabel:
          'This is not the coupon code, just the name of the discount, e.g. Christmas 2024.',
        period: 'Validity period',
        periodLabel: 'Without an expiry date, the coupon(s) can be redeemed indefinitely.',
        validFrom: 'Start',
        validUntil: 'End',
        discount: 'Discount',
        discountRalue: 'Discount value',
        discountRalueLabel: 'It can only be modified until the first redemption.',
        discountValue: 'Discount value',
        discountMaxValue: 'Maximum discount value',
        minimumCartValue: 'Minimum cart value',
        redemptionLimit: 'Redemption limit',
        redemptionLimitLabel:
          'How many times can a coupon code be redeemed?  If disabled, the coupon codes can be redeemed an infinite number of times.',
        authoriseRefund: 'Authorise refund',
        authoriseRefundLabel:
          'This is useful information for our customer service, it has no effect on orders.',
        isRestricted: 'Restricted to events',
        pcs: 'pcs',
      },
    },
    couponCode: {
      title: 'Coupon codes',
      redemptionLimit: 'Redemption limit',
      noneRedemptionLimit: 'none',
      creationType: {
        manually: 'Manually',
        generate: 'Generate',
        upload: 'Upload',
      },
      archived: 'Cannot add new coupon codes to archived coupons.',
      manuallyForm: {
        couponCode: 'Coupon code',
        chars: '4-32 characters',
        tooltip: {
          characters: 'Allowed: {{ allowedChars }}<1 />Forbidden: {{ forbiddenChars }}',
          save: 'Save',
        },
        errorMsg: {
          required: '{{ fieldNameEn }} is required.',
          forbiddenChars: '\' / " < > # % { } |  ^ ~ [ ] ` are not allowed.',
          minLength: 'The coupon code must be at least 4 characters.',
          maxLength: 'Cannot be greater than 32 characters.',
        },
        snackbar: {
          saveSucceeded: 'Saved successfully!',
          saveFailed: 'Failed to save.',
          duplicateCode: 'This couponcode is active in a Coupon.',
        },
      },
      generateForm: {
        quantity: 'Quantity *',
        codeLength: 'Code length *',
        prefix: 'Prefix',
        postfix: 'Postfix',
        pattern: 'Pattern *',
        charset: {
          label: 'Charset *',
          numeric: 'Numbers',
          alphabetic: 'Letters',
          alphanumeric: 'Alphanumeric',
        },
        chars: 'max. 8 characters',
        preview: 'Preview',
        generate: 'Generate',
        errorMsg: {
          required: '{{ fieldNameEn }} is required.',
          forbiddenChars: '\' / " < > # % { } |  ^ ~ [ ] ` are not allowed.',
          prefixPostfixMaxLength: 'Cannot be greater than 8 characters.',
        },
        dialog: {
          title: 'Warning',
          content: 'You want to generate more than 10 000 coupon codes. Are you sure?',
          cancel: 'Cancel',
        },
        snackbar: {
          generateSucceeded: 'Generating in progress.',
          generateCompleted: 'Generating completed!',
          generateFailed: 'Failed to generate.',
        },
      },
      list: {
        actionType: {
          label: 'Action',
          activeSelected: 'Activate selected',
          deactivatedSelected: 'Deactivate selected',
          downloadSelected: 'Download selected',
          activeAll: 'Activate all',
          deactivatedAll: 'Deactivate all',
          downloadAll: 'Download all',
        },
        action: {
          label: {
            chooseAction: 'Choose action',
            activate: 'Activate ({{ summary }})',
            deactivate: 'Deactivate ({{ summary }})',
            download: 'Download ({{ summary }})',
          },
        },
        table: {
          tabs: {
            active: 'Active',
            deactivated: 'Deactivated',
          },
          header: {
            code: 'Coupon code',
            redemptionCount: 'Redemption',
          },
        },
        fileName: 'Tixa_coupon',
      },
    },
    statistics: {
      tile: {
        title: {
          ticket: 'Ticket',
          redemption: 'Redemption',
          ticketRevenue: 'Ticket revenue',
          discount: 'Discount',
          growth: 'Growth',
        },
        tooltip: {
          ticket: 'tickets bought with the coupon',
          redemption: 'number of coupon redemptions',
          ticketRevenue: 'revenue from tickets purchased with the coupon',
          discount: 'amount of discounts given for coupon redemption',
          growth: 'current coupon redemptions compared to the previous period',
        },
      },
      graph: {
        series: {
          ticket: 'Ticket',
          redemption: 'Redemption',
        },
      },
      list: {
        tabs: {
          detailed: 'Detailed',
          summary: 'Summary',
        },
        heading: {
          id: 'ID',
          name: 'Name',
          location: 'Location',
          eventStart: 'Event start',
          purchasedTicketCount: 'Purchased tickets',
          redemptionCount: 'Redemption',
        },
        detailed: {
          ticketType: 'Ticket type',
          originalPrice: 'Original price',
          quantity: 'Quantity',
          discount: 'Discount',
          ticketPrice: 'Reduced price',
          grossRevenue: 'Gross revenue',
        },
        summary: {
          total: 'Total',
          quantity: 'Quantity',
          grossRevenue: 'Gross revenue',
        },
      },
    },
  },
  ntak: {
    attractionRegistration: {
      title: 'Registration',
      breadcrumb: {
        dashboard: 'Dashboard',
        attraction: 'Attraction',
        registration: 'Registration',
      },
      data: {
        header: 'Registration',
        title: 'Basic data',
        save: 'Save',
        taxNumber: 'Tax number',
        taxNumberHelperText: 'Example: 11223344122',
        registrationNumber: 'Registration number',
        registrationNumberHelperText: 'Example: MK22001361',
        saveSuccess: 'Saved successfully!',
        saveFailed: 'Failed to save data.',
      },
      generateCert: {
        title: 'Generate Certificate',
        generate: 'Generate',
        download: 'Download',
        certCreated: 'Certificate generated successfully',
        certCreatedAt: 'Generated at: {{ date }}',
      },
      uploadCert: {
        title: 'Upload Certificate',
        upload: 'Upload',
        delete: 'Delete',
        chooseFile: 'Choose file...',
        certUploadedAt: 'Uploaded at: {{ date }}',
        fileNotCer: 'The uploaded file extension is not .cer.',
      },
      guide: {
        title: 'Helper',
        linkText: 'Detailed instructions for the registration process',
        emailText:
          'If you have any questions about the registration process and how to enter your details, please, refer to the guide. If you still cannot find the right answer, NTAK colleagues can help you at the email address below.',
      },
      content: {
        header:
          'To submit data, you need to follow the steps below on the <o>NTAK</o> website and here in the <s>TIXA</s> partner admin:',
        firstParagraph:
          '<l>Register</l> on the NTAK website using your Ügyfélkapu (Client Gate) account.',
        secondParagraph: 'Create your user account.',
        thirdParagraph:
          ' Register yourself as a <strong>service</strong> provider by clicking the + NEW SERVICE PROVIDER button.',
        fourthParagraph:
          'Register your <o>service location</o>(s) using the + Add New Service Location link, which can be accessed by clicking on Open in the table.',
        fifthParagraph:
          'Click on the arrow at the bottom of the Attraction column to go to the <o>Attraction module</o>.',
        sixthParagraph:
          'In the Service Locations menu, click Edit next to the service location and add your data.',
        seventhParagraph:
          'Click on <o>Software, Certificates</o>, then Add New Software and select TIXA from the list.',
        eighthParagraph:
          'Go back to the Service Sites menu and copy the <o>NTAK registration number</o> that you will find next to the service site name.',
        ninthParagraph:
          'In the <o>Basic Data</o> box, enter your tax number (without hyphens) and NTAK registration number, then click Save.',
        tenthParagraph:
          'In the <o>Generate Certificate</o> box, generate a certificate and download it.',
        eleventhParagraph:
          'In the NTAK Attraction module, click on the <o>Software, Certificates</o> button for your service location, then click on the <o>Manage Certificates</o> button.',
        twelfthParagraph:
          'Upload the TIXA certificate to the NTAK site. If the upload Status does not change for minutes, feel free to refresh the page. If the upload is successful, the status will be ‘Certificate ready’.',
        thirteenthParagraph:
          'Download the resulting NTAK certificate and upload it back to the <o>Upload Certificate</o> box in the TIXA partner admin.',
      },
      errorMsg: {
        taxNumber: 'Tax number must be 11 characters long.',
        registrationNumber:
          'Registration number must be 2 alphabetic characters and 8 numeric characters.',
        verified: 'Your account is ready to provide data.',
      },
    },
    attractionList: {
      title: 'Attractions',
      breadcrumb: {
        dashboard: 'Dashboard',
        attraction: 'Attraction',
        list: 'Log',
      },
      table: {
        header: {
          orderId: 'Order ID',
          status: 'Status',
          processingId: 'Processing ID',
          orderType: 'Order type',
          creationTime: 'Creation time',
          submissionTime: 'Submission time',
          checkResponseTime: 'Check response time',
        },
      },
    },
    boxoffice: {
      title: 'Events',
      breadcrumb: {
        dashboard: 'Dashboard',
        boxOffice: 'Box office',
        events: 'Events',
        settings: 'Settings',
        onSiteTicketing: 'On-site ticketing',
      },
      settings: {
        title: 'Settings',
        payment: {
          paymentMethod: 'Payment methods',
          cashHUF: 'Cash HUF',
          cashEUR: 'Cash EUR',
          szepCard: 'SZÉP card',
          creditCard: 'Credit card',
          transfer: 'Transfer',
          other: 'Other',
          voucher: 'Voucher',
          roomLoan: 'Room loan',
        },
      },
      events: {
        tooltip: {
          editTickets: 'Edit tickets',
          sales: 'Sales',
        },
      },
      offlineTicket: {
        title: 'On-site ticketing',
        newOnsiteTicket: 'New on-site ticket',
        editOnsiteTicket: 'Edit on-site ticket',
        name: 'Name',
        quantity: 'Quantity',
        price: 'Price',
        ticketType: 'Ticket type name',
        uniqueTicketType: 'Custom ticket type',
        existingTicketType: 'Existing ticket type',
        actions: {
          ticketDeletionAllowed: 'You want to delete the {{ ticketName }} ticket. Are you sure?',
          ticketTypeNameHelper:
            'If you do not select a ticket type, you can add a custom ticket type name.',
          edit: 'Edit',
          delete: 'Delete',
          orderBy: 'Order by:',
        },
        orderLabels: {
          byId: 'by Id',
          byName: 'by Name',
          byLocation: 'by Location',
          byStart: 'by Start',
          byEnd: 'by End',
        },
        confirmationDialog: {
          title: 'Quantity zero',
          content: `You have not set the quantity, so you may end up with more tickets than you have seats. Are you sure you want it this way?`,
          confirm: 'Save',
          back: 'Back',
        },
      },
      sales: {
        salesHeader: 'Sales',
        type: 'Type',
        boxOffice: 'Box office',
        offlineTicket: 'Offline ticket',
        ticketType: 'Ticket type',
        price: 'Price',
        quantity: 'Quantity',
        invoice: 'Invoice',
        zipCode: 'Zip code',
        inland: 'Domestic',
        abroad: 'Foreign',
        country: 'Country',
        paymentMethod: 'Payment method',
        usedImmediately: 'Ticket(s) used immediately',
        accomodationInfo: "Customer's place of residence",
        btn: {
          boxOffice: 'Create',
          offlineTickets: 'Submit',
        },
        soldOutDialog: {
          title: 'Exceeded the limit.',
          content: 'Selected tickets include already sold out tickets. Please check!',
          btn: 'OK',
        },
        snackbar: {
          successfulSubmit: 'Successful submit',
          failedSubmit: 'Failed submit',
          soldOutTickets: 'You have exceeded the limit',
        },
        errorMsg: {
          required: '{{ fieldNameEn }} is required.',
          zipCodeMin: 'Zip cod must be greater than or equal to 1000.',
        },
      },
    },
  },
  settlement: {
    title: 'Settlement',
    breadcrumb: {
      dashboard: 'Dashboard',
      finance: 'Finance',
      settlement: 'Settlement',
      detailedSettlement: 'Detailed settlement',
    },
    help: {
      comissionContract: {
        info: 'You have a commission account, therefore, we will expect an invoice from you for the total ticket revenue (gross ticket revenue column) after your event has taken place. Once we receive it, we will issue a commission invoice and transfer the difference between the two invoices. <l>Details</l>',
        details: {
          billingInformation: {
            title: 'Billing information',
            content:
              'We will expect an invoice from you for the total ticket revenue (gross ticket revenue column) after your event has taken place. Once we receive it, we will issue a commission invoice and transfer the difference between the two invoices.',
          },
          billingAddress: {
            title: 'Billing address',
            content:
              'TIXA Hungary Kft. <1/>5600 Békéscsaba, Dobozi út 58. <1/>Tax number: 24813064-2-04',
          },
          postalAddress: {
            title: 'Postal address (please send the invoice here)',
            content: 'TIXA Hungary Kft. <1/>1091 Budapest, Üllői út 25.',
          },
          invoiceDetails: {
            title: 'Invoice details',
            list: '<1><2><3/></2><4>the type of services/goods must be "ticket sales" on the invoice (in Hungarian: jegyértékesítés)</4></1><1><2><3/></2><4>please include the event ID in the comment, and if possible the name of the event</4></1><1><2><3/></2><4>in the Settlement menu, you see the gross revenue</4></1><1><2><3/></2><4>method of payment: transfer (in Hungarian: átutalás)</4></1><1><2><3/></2><4>issued date: the date on which you issue the invoice</4></1><1><2><3/></2><4>payment deadline: 8 days</4></1><1><2><3/></2><4>delivery date: the date of the last event included on the invoice</4></1><1><2><3/></2><4>the tax number must appear on the invoice</4></1><1><2><3/></2><4>it must include the bank account number of the invoicing company</4></1>',
            content:
              'Invoices will be accepted from the company through which the purchases were made and with whom we have a contract. <1/>However, you can pay an invoice from anywhere, just indicate the invoice serial number in the transfer notes. <1/>We can only make a transfer once we have received the original invoice, we do not accept scanned invoices. However, we will gladly accept e-invoices. Please send a paper invoice to our postal address above or email us at penzugy@tixa.hu.',
          },
          vatRates: {
            title: 'Different VAT rates',
            content:
              'Before starting the sale, inform your personal contact if certain items should appear with a different VAT rate on the invoices (for example: if the ticket price includes a hot meal prepared on-site.)',
          },
          specialServices: {
            title: 'Invoices for special services',
            content:
              'If you have ordered wristbands, hard copy tickets or access control support, we will send you a separate invoice by email.',
          },
        },
      },
      ownContract: {
        info: 'You have a personal account, therefore, TIXA will invoice you (TIXA cost column). <l>Details</l>',
        details: {
          vatRates: {
            title: 'Different VAT rates',
            content:
              'Before starting the sale, inform your personal contact if certain items should appear with a different VAT rate on the invoices (for example: if the ticket price includes a hot meal prepared on-site.)',
          },
          specialServices: {
            title: 'Invoices for special services',
            content:
              'If you have ordered wristbands, hard copy tickets or access control support, we will send you a separate invoice by email.',
          },
          downloadReport: {
            title: 'Download Simplepay report',
            content:
              'You can download the details of the amounts paid out by SimplePay in a report from the <l>SimplePay admin</l> and forward this to your accountant. Once you sign in, you can download a report of all transfers by clicking on the menu items Finance - Transfers - Outgoing transfers (even filtering by date). This allows the accountant to match the invoices issued with the amount received from SimplePay. Transactions that are reported as a refund are also displayed as a balance report.',
          },
        },
      },
      close: 'Close',
    },
    detailedSettlement: {
      title: 'Detailed settlement',
      tabs: {
        online: 'Online',
        coupon: 'Coupon',
        boxOffice: 'Box office',
        others: 'Others',
        all: 'All',
      },
      overview: {
        tixaCost: 'Tixa cost',
        netIncome: 'Net revenue',
        vat: 'VAT {{ vat }}%',
        grossIncome: 'Gross revenue',
        tooltip: {
          tixaExpense: 'Tixas share of all categories (rounded), see items in green.',
          grossRevenue: 'The partners share of all categories, see items in blue.',
        },
      },
      online: {
        title: 'Online',
        ticketType: 'Ticket type',
        price: 'Price',
        quantity: 'Quantity',
        grossIncome: 'Ticket revenue (Gross)',
        commission: 'Commission ({{ commission }}%)',
        grossIncomeAfterCommission: 'Revenue - (Gross)',
        coupon: 'Coupon',
        all: 'Total',
        tooltip: {
          gross: 'Gross',
        },
      },
      offline: {
        title: 'Box office',
      },
      handlingFee: {
        title: 'Handling fee',
        grossHFIncome: 'Handling fee',
        commission: 'Commission ({{ commission }}%)',
        grossIncome: 'Revenue ({{ grossIncome }}%)',
        tooltip: {
          gross: 'Gross',
        },
      },
      transferFee: {
        title: 'Transfer fee',
        grossTransferIncome: 'Gross TF revenue',
        commission: 'Commission ({{ commission }}%)',
        grossIncome: 'Gross revenue ({{ grossIncome }}%)',
      },
      refund: {
        title: 'Refund',
        grossRefund: 'Sum',
        commission: 'Commission ({{ commission }}%)',
        tooltip: {
          gross: 'Bruttó',
          commission: 'Commission after the refunded tickets.',
          grossRefund:
            'The amount of the refunded tickets is not included in the online ticket revenue.',
        },
      },
      guestTicket: {
        title: 'Guest ticket',
        quantity: 'Quantity',
        price: 'Unit price',
        sum: 'Total',
      },
      coupon: {
        title: 'Kupon',
        ticketType: 'Jegytípus',
        price: 'Ár',
        quantity: 'Darab',
        grossIncome: 'Jegybevétel',
        commission: 'Jutalék ({{ commission }}%)',
        grossIncomeAfterCommission: 'Bevétel',
        coupon: 'Kupon',
        all: 'Összesen',
        tooltip: {
          gross: 'Bruttó',
        },
      },
    },
    table: {
      toolbar: {
        settlementStatus: {
          label: 'Settlement status',
          all: 'All',
          paid: 'Paid',
          pending: 'Awaiting settlement',
        },
      },
      header: {
        status: 'Status',
        id: 'ID',
        name: 'Name',
        eventStart: 'Event start',
        location: 'Location',
        grossRevenue: 'Ticket revenue',
        grossRevenueInfo: 'Gross',
        commission: 'Tixa expense',
        commissionInfo: 'Gross',
        payment: 'Payment',
      },
      content: {
        statusInfo: {
          onSale: 'On sale',
          closedSale: 'Closed sale',
        },
        details: 'Detailed settlement',
        total: 'Total',
        noData: 'No data matching the filter.',
      },
      footer: {
        rowsPerPage: 'Rows per page',
        dense: 'Dense',
      },
    },
  },
  guestInvite: {
    title: 'Invite',
    breadcrumb: {
      dashboard: 'Dashboard',
      guests: 'Guests',
      invite: 'Invite',
      inviteStatistics: 'Statistics',
    },
    page: {
      btn: {
        invite: 'Invite',
      },
    },
    tooltip: {
      invite: 'Invite guests',
      statistics: 'Statistics',
    },
    label: {
      invited: 'Invited',
    },
    form: {
      saveSuccess: 'Invitation sent successfully!',
      saveFailed: 'Failed to send invitation.',
      label: {
        category: 'Category',
        ticketType: 'Ticket type',
        firstName: 'First name',
        lastName: 'Last name',
        company: 'Organisation',
        email: 'Email *',
        language: 'Language',
        comment: 'Comment',
        individual: 'Custom',
        tickets: 'Tickets',
        guestData: 'Guest data',
      },
      helperText: {
        name: 'Name appearing on the ticket and in the email. (not required)',
        company: 'Organisation appearing on the ticket and in the email. (not required)',
        comment: 'Inside information, guest will not see it. (not required)',
      },
      btn: {
        invite: 'Invite',
        download: 'Download',
      },
      error: {
        required: '{{ fieldNameHu }} is required',
        ticketTypeRequired: 'At least one ticket type is required',
      },
    },
    download: {
      fileName: 'Tixa_guests',
    },
    statistics: {
      title: 'Statistics',
      label: {
        quantity: 'Quantity',
        category: 'Category',
        ticketType: 'Ticket type',
      },
      text: {
        total: 'Összesen',
      },
    },
  },
  guestlist: {
    title: 'Guestlist',
    breadcrumb: {
      dashboard: 'Dashboard',
      guests: 'Guests',
      guestlist: 'Guestlist',
    },
    table: {
      header: {
        id: 'ID',
        lastName: 'Last name',
        firstName: 'First name',
        email: 'Email',
        event: 'Event',
        user: 'User',
        date: 'Date',
      },
      details: {
        ticketType: 'Ticket type',
        quantity: 'Quantity',
      },
    },
  },
  guestDetails: {
    title: 'Guest details',
    breadcrumb: {
      dashboard: 'Dashboard',
      guests: 'Guests',
      guestDetails: 'Guest details',
    },
    guestData: {
      title: 'Guest',
      firstName: 'First name',
      lastName: 'Last name',
      companyName: 'Organization',
      email: {
        label: 'Email',
        invalidEmail: 'Must be a valid email address.',
      },
      language: {
        title: 'Email language',
        hu: 'Hungarian',
        en: 'English',
      },
      modifyEmailInfo:
        'Changing the email address does not resend the email automatically. If you have sent the ticket to the wrong address the first time, you may want to regenerate the barcode and then send it to the new address.',
    },
    invitationData: {
      title: 'Invitation',
      invitationId: 'Invitation ID',
      eventId: 'Event ID',
      event: 'Event',
      eventDate: 'Event date',
      user: 'User',
      dateOfSending: 'Sent',
    },
    guestTickets: {
      title: 'Tickets',
      table: {
        header: {
          serialNumber: 'Serial nr.',
          ticketId: 'Ticket ID',
          ticketType: 'Ticket type',
          category: 'Category',
          barcode: 'Barcode',
          seat: 'Sector/row/seat',
          sent: 'Sent',
        },
        footer: {
          showAll: 'Show all',
        },
      },
      actionType: {
        label: 'Action',
        regenerate: 'Regenerate',
        resend: 'Send',
        delete: 'Delete',
        tooltip: 'Select at least one ticket',
      },
      snackbar: {
        regenerate: 'Barcode(s) regenerated successfully.',
        resend: 'Ticket(s) sent successfully.',
        delete: 'Ticket(s) deleted successfully.',
      },
      actionInfo: {
        regenerate:
          'Delete old barcode, create new one. Tickets with a new barcode will not be sent automatically to the guest, if necessary they can be done with the ‘Send’ action.',
        delete: 'Ticket(s) will be deleted permanently.',
      },
    },
    guestHistory: {
      title: 'History',
      table: {
        header: {
          user: 'User',
          action: 'Action',
          date: 'Date',
          details: 'Details',
        },
      },
      actionType: {
        label: 'Action',
        all: 'All',
        modifyEmail: 'Modify email',
        regenerate: 'Regenerate',
        resend: 'Send',
        delete: 'Delete',
      },
    },
    guestComment: {
      title: 'Comment',
    },
  },
  guestSettings: {
    title: 'Settings',
    breadcrumb: {
      dashboard: 'Dashboard',
      guests: 'Guests',
      guestSettings: 'Settings',
    },
    type: {
      user: 'User',
      organizer: 'Organizer',
    },
    info: {
      user: 'The user-level settings override the organiser-level settings for tickets you send.',
      organizer:
        'This will be the default setting for all users who have permission to send guest tickets.',
    },
    replyTo: {
      title: 'Reply to settings',
      info: "The email address set here will receive the guest's reply to the invitation.",
      form: {
        email: 'Email',
        save: 'Save',
      },
      confirmation: {
        titleSucceeded: 'The reply-to email has been successfully confirmed.',
        titleFailed:
          'The reply-to email confirmation failed. Please try again or resend the confirmation email.',
        button: 'Back to settings',
        info: 'Click on the link in the email sent to your email address to confirm the change. If you cannot find it, <1>resend it!</1>',
      },
    },
    email: {
      title: 'Customized email content',
      content: {
        english: 'English content',
        hungarian: 'Hungarian content',
        toolbar: {
          dynamicContent: 'Dynamic content',
          reset: 'Restore original text',
          copy: 'Copy',
          copied: 'Copied',
        },
        action: {
          cancel: 'Cancel',
          preview: 'Preview',
          save: 'Save',
        },
      },
    },
  },
  guestLimit: {
    title: 'Limit management',
    breadcrumb: {
      dashboard: 'Dashboard',
      guests: 'Guests',
      limitManagement: 'Limit management',
    },
    users: {
      info: 'The list of events available to the User can be edited in the Users / List menu.',
      table: {
        header: {
          user: 'User',
          email: 'Email',
          limit: 'Limit',
        },
        content: {
          none: 'none',
          tooltip: {
            edit: 'Edit',
          },
        },
      },
    },
    events: {
      title: 'User limit',
      breadcrumb: {
        dashboard: 'Dashboard',
        guests: 'Guests',
        limitManagement: 'Limit management',
        users: 'Events',
      },
      info: 'Set the number of complimentary tickets that can be sent by users who have permission to invite guests.',
      table: {
        header: {
          status: 'Status',
          id: 'ID',
          name: 'Name',
          location: 'Location',
          eventStart: 'Event start',
          guestLimit: 'Limit',
        },
        content: {
          noneGuestLimit: 'none',
        },
      },
      ticketTypes: {
        table: {
          header: {
            name: 'Ticket type',
            limit: 'Limit',
            sent: 'Sent',
            free: 'Free',
            unlimited: 'Unlimited',
          },
          content: {
            confirmInfo: {
              save: 'Save',
              required: 'The limit is required.',
              sentTickets: 'The limit cannot be less than the number of tickets sent.',
              cancel: 'Cancel',
            },
          },
        },
      },
    },
  },
  seatMap: {
    dialog: {
      title: 'Error!',
      btn: 'ok',
    },
    seatMapInstance: {
      addBtn: 'Add',
      helpers: {
        inactive:
          'A color with a darker contrast than the original indicates a place that is inactive for sale, but can be sent as a guest ticket.',
      },
    },
    guestInvite: {
      openSeatMapBtn: 'Open seat map',
      selectedSeatsTitle: 'Selected seats',
      list: {
        header: {
          id: 'Id',
          category: 'Category',
          quantity: 'Quantity',
        },
        detailedHeader: {
          id: 'Id',
          sector: 'Sector',
          row: 'Row',
          seat: 'Seat',
        },
        ticketDeletionAllowed: 'You want to delete the {{ ticketName }} ticket. Are you sure?',
      },
      reachedLimit: 'You have reached your limit.',
      limitError:
        'You have exceeded the limit for ticket type <strong>{{ ticketTypeName }}</strong> with <strong>{{ exceededQty }}</strong>. Change the quantity.',
    },
  },
  personalData: {
    isActive: 'Active',
    isInactive: 'Inactive',
    header: 'Personal data',
    firstName: 'Firstname *',
    lastName: 'Lastname *',
    email: 'Email *',
    phoneNumber: 'Phone number *',
    submit: 'Update user',
    errorMsg: {
      required: '{{ fieldNameEn }} is required.',
      firstNameLength: 'Firstname must be at least 3 chars.',
      lastNameLength: 'Lastname must be at least 3 chars.',
      validEmail: 'Must be a valid email address.',
      validPhoneNumber: 'Must be a valid email address.',
    },
  },
  companyData: {
    header: 'Organizer account',
    helperText:
      'Only your contact can change your account details. You can submit your change request by filling out the form below.',
    companyDataHeader: 'Company data',
    accountName: 'Organizer account name *',
    link: 'Link *',
    accountNameInfo: 'An organiser account with your events will be created under this name.',
    country: 'Country',
    countryHu: 'Hungary',
    taxNumber: 'Tax number {{ required }}',
    headquarters: 'Headquarters',
    companyName: 'Company name *',
    postalCode: 'Postal code {{ required }}',
    city: 'City {{ required }}',
    addressDetails: 'Address {{ required }}',
    isDifferentMailingAddress: 'Mailing address is different',
    mailingAddress: 'Mailing address',
    moreInformation: 'More information',
    representativeName: 'Representative name *',
    vatStatus: 'VAT {{ required }}',
    vatStatusOptions: {
      vatExemptStatus: 'VAT exempt status',
      exemptFromVat: 'Exempt from VAT',
      0: '0%',
      5: '5%',
      18: '18%',
      27: '27%',
      other: 'Other - inform your contact',
    },
    selectVat: 'Select VAT...',
    bankAccountNumber: 'Bank account number {{ required }}',
    registeredNumber: 'Company registration number',
    contractForms: {
      header: 'Contract forms',
      comission: 'Comission',
      comissionInfo:
        'Tixa issues the invoices, receives the revenue and we settle after the event. <1>More details...</>',
      own: 'Personal',
      ownInfo: 'Invoices are issued in your name, you receive the revenue. <1>More details...</>',
      unknown: 'I do not know',
    },
    registerComment: 'Comment',
    create: 'Create company',
    modify: 'Modify company data',
    send: 'Send',
    dialog: {
      title: 'Saved successfully!',
      content: 'Your organizer account has been created successfully. We will contact you soon!',
      btn: 'Ok',
    },
    errorMsg: {
      required: '{{ fieldNameEn }} is required.',
      taxNumberFormat: 'Tax number format should be 11111111-1-11',
      postalCodeFormat: 'Postal code must be 4 characters.',
      accountNumberFormat: 'Account number format should be 11111111-11111111-(11111111)',
      uniqueLink: 'Link must be unique.',
    },
  },
  accountProfile: {
    title: 'Profile',
    breadcrumb: {
      dashboard: 'Dashboard',
      user: 'Account',
    },
    successMsg: {
      successfulUpdate: 'Personal data has been updated successfully.',
    },
    errorMsg: {
      failedUpdate: 'Could not update personal data.',
    },
  },
  accountCompanyData: {
    title: 'Company data',
    breadcrumb: {
      dashboard: 'Dashboard',
      account: 'Account',
      companyData: 'Company data',
    },
    organizerDataHeader: 'Organizer data',
    accountName: 'Organizer account name',
    link: 'Link',
    companyDataHeader: 'Company data',
    companyName: 'Company name',
    representativeName: 'Representative name',
    taxNumber: 'Tax number',
    bankAccountNumber: 'Bank account number',
    vatStatus: 'VAT',
    registeredNumber: 'Company reg. number',
    vatStatusOptions: {
      AAM: 'VAT exempt status',
      TAM: 'Exempt from VAT',
      0: '0%',
      5: '5%',
      18: '18%',
      27: '27%',
    },
    country: 'Country',
    address: 'Address',
    mailingAddress: 'Mailing address',
    moreInformation: 'More information',
    contractForm: 'Contract form',
    contractForms: {
      comissionContract: 'Comission',
      ownContract: 'Personal',
      unknown: 'I do not know',
    },
    registerComment: 'Comment',
    successMsg: {
      successfulRequest: 'Request sent successfully!',
    },
    errorMsg: {
      createFailed: 'Could not create organizer data',
      failedRequest: 'Could not send the request',
    },
  },
  userInvite: {
    title: 'Invite user',
    breadcrumb: {
      dashboard: 'Dashboard',
      users: 'Users',
      invite: 'Invite',
    },
    form: {
      title: 'Edit permissions',
      selectOwnRole: {
        myRoles: 'My roles',
        selectedUserRoles: "{{ lastName }} {{ firstNameHu }}' roles",
        myRolesPlaceholder: 'Select your role...',
        selectedUserRolesPlaceholder: "Select from user's roles...",
      },
      email: 'Email',
      role: 'Role',
      customizedRoleName: 'Customized role name',
      selectRole: 'Select a role...',
      event: 'Event',
      eventPlaceholder: 'Search events...',
      selectEvents: 'Select events...',
      confirmSelection: 'Save',
      cancelSelection: 'Cancel',
      searchEvents: 'Search',
      hasPermissionToAllEvents: 'All events',
      infoToAllEventsSelect: 'Applies to active, archived and future events',
      permissions: {
        title: 'Permissions',
        permissionDetails: 'View and edit permissions',
        event: {
          label: 'Event',
          submenus: {
            event: {
              label: 'Event',
              create: 'Create',
              list: 'List',
              edit: 'Edit',
              limit: 'Limit management',
              history: 'History',
            },
            statistics: {
              label: 'Statistics',
              sales: 'Sales',
              report: 'Report',
              customizedStatistics: 'Customized statistics',
            },
            marketing: {
              label: 'Marketing',
              coupon: 'Coupon',
            },
            finance: {
              label: 'Finance',
              settlement: 'Settlement',
            },
            guestTicket: {
              label: 'Guest ticket',
              invite: 'Invite',
              list: 'Guestlist',
              limitManagement: 'Limit management',
              settings: 'Settings',
              allUsers: 'All users',
            },
            boxoffice: {
              label: 'Box office',
              events: 'Events',
              settings: 'Settings',
            },
            newsletter: {
              label: 'Newsletter',
            },
          },
        },
        entry: {
          label: 'Entry',
          submenus: {
            entrySystem: {
              label: 'Entry system',
              list: 'List',
              edit: 'Edit',
              delete: 'Delete',
            },
          },
        },
        ntak: {
          label: 'NTAK',
          submenus: {
            attraction: {
              label: 'Attraction',
              listEdit: 'List and edit',
            },
          },
        },
        users: {
          label: 'Account settings',
          submenus: {
            account: {
              label: 'Account',
              myAccount: 'My account',
              edit: 'Profile',
            },
            invitedUsers: {
              label: 'Users',
              list: 'List',
              invite: 'Invite',
              edit: 'Edit',
              delete: 'Delete',
              history: 'History',
            },
          },
        },
        confirm: 'Confirm',
        cancel: 'Cancel',
        back: 'Back',
      },
      invite: 'Invite',
      addAccount: 'Add account',
      addEvents: 'Add events',
      edit: 'Edit account',
      errorMsg: {
        required: '{{ fieldNameEn }} is required.',
        validEmail: 'Must be a valid email address.',
        requiredPermissions: 'You must select permissions!',
      },
    },
    confirmInvitation: {
      succeeded: {
        header: 'Confirmed succesfully',
        description:
          'You confirmed the invitation successfully. You already logged in with another address. First <1>log out</1> then log in with the {{ email }} email.',
      },
      failed: {
        header: 'Confirmed failed',
        description: 'Confirmation has been failed. Please try again!',
      },
    },
    dialogs: {
      accessDenied: {
        title: 'Access denied',
        content: `User already exists with the email address you entered, but you do not have permission to edit it!`,
      },
      accessAllowed: {
        title: 'Edit user',
        content: `User already exists with the email address you entered. <1>Edit user</1>`,
      },
      resendInvitation: {
        title: 'Resend invitation',
        successContent: `Invitation email resend successfully!`,
        failedContent: `Resend invitation email failed. Please, try again!`,
      },
      confirm: 'Ok',
    },
    successMsg: {
      successfulInvite: 'User has been invited succesfully',
    },
    errorMsg: {
      failedInvite: 'Could not invite the user',
      existingAccount: 'Account already exists',
    },
  },

  users: {
    title: 'Users',
    breadcrumb: {
      dashboard: 'Dashboard',
      users: 'Users',
      list: 'List',
    },
    inviteUser: 'Invite user',
    table: {
      tabs: {
        all: 'All',
        editable: 'Editable',
      },
      toolbar: {
        role: 'Role',
        search: 'Search...',
      },
      header: {
        name: 'Name',
        email: 'Email',
        role: 'Role',
        events: 'Events',
        isVerified: 'Verified',
      },
      access: {
        all: 'All',
        customized: 'Customized',
      },
      actions: {
        edit: 'Edit role',
        add: 'Add role',
        delete: 'Delete user',
        editEmail: 'Edit email',
        resendInvitation: 'Resend invitation',
      },
      footer: {
        dense: 'Dense',
        rowsPerPage: 'Rows per page',
      },
    },
    userAddRole: {
      addRole: 'Add role',
      addRoleToUser: 'Add role to user',
      errorMsg: {
        failedCreateRole: 'Could not add role to the user',
        hasTheSelectedRole: 'The user already has the selected role.',
        hasHigherLevelRole: 'The user already has a higher level role for all events.',
      },
    },
    userEditRole: {
      editRole: 'Edit role',
      deleteRole: 'Delete role',
      stepper: {
        chooseRole: 'Choose role',
        editRole: 'Edit role',
        confirm: 'Confirm',
        next: 'Next',
        back: 'Back',
      },
      selectRole: {
        selectedUserRoles: "{{ firstNameEn }} {{ lastName }}'s roles",
        selectedUserRolesPlaceholder: "Select from users's roles...",
      },
      confirmModifications: {
        events: 'Events',
        allEvents: 'All events',
      },
      errorMsg: {
        failedRoleUpdate: "Could not updated the user's role",
        failedEventsAdd: "Could not add events to the user's role",
        failedEventsDelete: "Could not delete events from the user's role",
      },
    },
    userEditEmail: {
      header: 'Edit email and resend invitation',
      submit: 'Edit and invite',
    },
    userDeleteRole: {
      confirmationDialog: {
        title: 'Delete confirmation',
        roleDeleteContent: `You want to delete the '{{ roleName }}' role. Are you sure?`,
        lastRoleDeleteContent:
          "It is the last role that belongs to this user so the user will disapper from the list. If you want to re-assign a role to this user, you can do it in the 'Invite' menu.",
        confirm: 'Delete',
        back: 'Back',
      },
      errorMsg: {
        deleteRoleFailed: 'Could not delete the selected role',
      },
    },
    userDelete: {
      confirmationDialog: {
        title: 'Delete confirmation',
        userDeleteContent: `You want to delete the user associated with the '{{ email }}' address. Are you sure?`,
        confirm: 'Delete',
        back: 'Back',
      },
      errorMsg: {
        deleteUserFailed: 'Could not delete the selected user',
      },
    },
    roles: {
      user: 'User',
      ingyenesSzervezo: 'Free Organizer',
      szervezo: 'Organizer',
      rendezvenyszervezo: 'Event organizer',
      penzugy: 'Finance',
      marketing: 'Marketing',
      beleptetoManager: 'Entry manager',
      penztaros: 'Cashier',
      tiszteletjegyKuldes: 'Guest ticket sender',
      jegyfogyasKovetes: 'Ticketing tracking',
      belepteto: 'Entry',
      egyeni: 'Customized',
    },
  },
  userHistory: {
    title: 'History',
    breadcrumb: {
      dashboard: 'Dashboard',
      users: 'Users',
      history: 'History',
    },
    table: {
      toolbar: {
        actionType: 'Action',
        all: 'All',
        search: 'Search...',
        from: 'From',
        to: 'To',
      },
      header: {
        user: 'User',
        action: 'Action',
        modified: 'Modified',
        created: 'Date',
        status: 'Status',
      },
      statusSuccess: 'Successful operation',
      statusFailed: 'Unseccessful operation',
      details: 'Details',
      deletedAccount: 'Deleted account',
      footer: {
        dense: 'Dense',
        rowsPerPage: 'Rows per page',
      },
      empty: 'No Data',
    },
    details: {
      user: 'User:',
      invitedUser: 'Invited user:',
      newAccount: 'New account',
      modifiedAccount: 'Modified account:',
      deletedAccount: 'Deleted account:',
      events: 'Events:',
      deletedEvents: 'Deleted events:',
      newRole: 'New role:',
      allEvents: 'all',
      info: {
        inviteUser: 'Invite user by email address',
        editPermissions: 'Edit user permissions',
        addEvents: 'Add events to an existing account',
        addAccount: 'Add a new level of access',
        deleteEvents: 'Delete events from an existing account',
        deleteAccount: 'Delete a level of access',
      },
      error: 'Something went wrong.',
    },
    actions: {
      inviteUser: 'Invite user',
      editPermissions: 'Edit permissions',
      addEvents: 'Add events',
      addAccount: 'Add account',
      deleteEvents: 'Delete events',
      deleteAccount: 'Delete account',
    },
  },
  recommendation: {
    title: 'Recommendation',
    breadcrumb: {
      dashboard: 'Dashboard',
      recommendation: 'Recommendation',
    },
    content: {
      header: 'Service introduction',
      ticketingService: {
        header: 'Ticketing service',
        firstParagraph:
          'Our ticketing service is available in two types of schemes - commission account or personal account.',
        secondParagraph:
          'It is possible to sign up with an active company with a TAX number, even as a sole trader.',
        secondSubParagraph:
          'if you do not have a company or business registered in Hungary, you can only choose the commission account',
        thirdParagraph:
          'After signing with us, your TIXA account is created and a contract is concluded for an indefinite period - no need to sign a contract for every new event.',
        fourthParagraph:
          'The TIXA account is a private, password-protected, unique partner interface where the organisers can enter the details of their event, basic ticketing information, track statistics; a designated contact person will help you to launch the sale from the unique link (page).',
        fifthParagraph:
          'Before signing a contract, we will help you to get to know our two schemes so that you can choose the best one for you.',
      },
      contractForm: {
        commission: 'Commission',
        personal: 'Personal',
      },
      firstSteps: {
        header: 'First steps',
        nonHuCompanyOrContract: 'Non-HU company',
        company: 'HU company',
        launch: 'Launch',
        simplePay: 'SimplePay',
        szamlazzHu: 'Számlázz.hu',
        commission: {
          nonHuCompanyOrContract: 'Any company, but you can only receive the net income',
          company: 'VAT paying businesses',
          launch: '1-2 working days',
          simplePay: 'TIXA Hungary Kft. is in contract with them',
          szamlazzHu: "Through TIXA Hungary Kft.'s account we issue the invoice",
        },
        personal: {
          nonHuCompanyOrContract: '-',
          company: 'Recommended for TAX free businesses as well',
          launch: '7-10 working days',
          simplePay: "The partner's company also contracts with them (1.905 HUF)",
          szamlazzHu: "We bill through the partner's account (free of charge)",
        },
      },
      customerInvoice: {
        header: 'Customer invoice',
        firstParagraph: 'TIXA Hungary Kft. invoices to customers',
        secondParagraph: 'All tickets are automatically accompanied by an electronic invoice',
        thirdParagraph: 'This fee is paid by TIXA',
        commission: {
          fourthParagraph: 'TIXA Hungary Kft. will appear as the issuer of the invoice',
        },
        personal: {
          fourthParagraph: "The partner's company/business appears as the issuer of the invoice",
        },
      },
      revenueFromTickets: {
        header: 'Revenue from tickets',
        firstParagraph: 'Revenue from tickets + handling  fee',
        commission: {
          secondParagraph: 'TIXA Hungary Kft. receives the revenue',
          thirdParagraph: 'The handling fee paid bythe customer is 100% due to TIXA',
        },
        personal: {
          secondParagraph: "The partner's company receives the revenue",
          thirdParagraph:
            'The handling fee paid by the customer is shared between the partner and TIXA',
        },
      },
      handlingFee: {
        header: 'Handling fee',
        commission: {
          share: 'TIXA 100%',
        },
        personal: {
          share: 'Partner - TIXA',
          shareValue: '50% - 50%',
        },
        content: {
          fixedFee: 'FIX 150 HUF',
          perTransaction: 'per transaction',
          percentageOfTicketPrice: '3% of the ticket price',
          fromGrossValue: 'the gross value of the tickets purchased',
        },
      },
      settlement: {
        header: 'Settlement',
        firstParagraph: 'Against invoice by bank transfer',
        commission: {
          secondParagraph: 'After the event',
          thirdParagraph:
            'The partner issues an invoice to TIXA with the gross value of the ticket receipts',
          fourthParagraph:
            'TIXA invoices the partner for the commission calculated on the gross ticket price',
        },
        personal: {
          secondParagraph: 'After the event or purchases made within a certain period',
          thirdParagraph:
            'TIXA issues an invoice to the partner a commission calculated on the gross ticket price \n+ 50% of the handling fee \n+ the postage',
        },
      },
      partnerExpenses: {
        header: 'Partner expenses',
        firstParagraph: "TIXA's fee is a commission on ticket sales",
        secondParagraph: 'No joining fees or other charges',
        thirdParagraph:
          "If you don't have any active events, the TIXA cooperation does not cost anything",
        commission: {
          simplePay: {
            header: 'SimplePay',
            content: 'This cost is charged to TIXA Hungary Kft.',
          },
        },
        personal: {
          simplePay: {
            header: 'SimplePay',
            accountManagementFee: 'account management fee',
            accountManagementFeeValue: '0 HUF',
            joiningFee: 'joining fee',
            joiningFeeValue: '1.905 HUF',
            turnoverTax: 'turnover tax *',
            turnoverTaxValue: '1.5% + VAT',
            turnoverTaxDetails: '*the 50% of the handling fee approximately covers this',
          },
        },
      },
      personalContact: {
        header: 'Personal contact',
        content: 'You can contact your own designated contact person if you have any questions!',
      },
      customerService: {
        header: 'Customer service',
        content:
          'We provide customer service by e-mail on working days between 10am and 4pm, where we will respond within 24 hours.',
      },
      additionalServices: {
        header: 'Additional services',
        ticketAndAccessControl: {
          header: 'Ticket and access control',
          info: 'barcode scanner (rent) or TIXA mobile app (free)',
        },
        onSiteTicketingOptions: {
          header: 'On-site ticketing options',
          info: 'box-office function, hard copy ticket',
        },
        advertisingPackages: 'Advertising packages',
        wristband: 'Wristband',
        plasticCard: 'Plastic card',
      },
    },
    confirmEmailFirst: 'Confirm email first',
    continueWithFreeAccount: 'Continue with free account',
    addCompanyData: 'Add company data',
    menuButton: 'Recommendation',
  },
  offer: {
    title: 'Offer',
    breadcrumb: {
      dashboard: 'Dashboard',
      offers: 'Offer',
    },
    account: {
      freeAccount: 'Free account',
      proAccount: 'Pro account',
      freeEvent: 'Create free event',
      paidEvent: 'Create paid event',
      statistics: 'Statistics',
      detailedStatistics: 'Detailed statistics',
      finance: 'Finance',
      enrolement: 'Enrolement',
      guestTicket: 'Guest ticket',
      printing: 'Ticket printing',
    },
    dialog: {
      title: 'Free organizer',
      content: 'An organiser account will be set up to help you create free events',
      cancel: 'Cancel',
      create: 'Create',
    },
    errorMsg: {
      createFreeAccountSucceeded: 'Create a free account successfully',
      createFreeAccountFailed: 'Create a free account failed',
      alreadyHasOrganizer: 'You already have an organizer account',
    },
    continue: 'Create',
    details: 'Details',
  },
  resendConfirmationEmail: {
    header: 'Email confirmation token expired?',
    description:
      'Please enter the email address associated with your account and we will email you a link to confirm your email.',
    back: 'Back',
  },
  resetPassword: {
    header: 'Forgot your password?',
    description:
      'Please enter the email address associated with your account and We will email you a link to reset your password.',
    back: 'Back',
  },
  newPassword: {
    header: 'Change your password!',
    description: 'Please enter your new password for the email address {{ email }}.',
    password: 'New password',
    confirmPassword: 'Confirm new password',
    submit: 'Change password',
    errorMsg: {
      passwordLength: 'Password must be at least 6 characters',
      passwordRequired: 'Password is required',
      confirmPasswordRequired: 'Confirm password is required',
      passwordMatch: 'Passwords must match',
    },
  },
  sendEmail: {
    email: 'Email address',
    submit: 'Send request',
    emailSent: 'Email sent!',
    sentDescription:
      "We've sent an email to the {{ email }} email address. \nPlease check your inbox!",
    errorMsg: {
      validEmail: 'Must be a valid email address',
      required: 'Email is required',
      sendConfirmationEmailFailed: 'Failed to send confirmation email',
      sendPasswordRecoveryEmailFailed: 'Failed to sen password recovery email',
    },
  },
  expiredToken: {
    header: 'Token expired!',
    newPasswordDescription: 'Token expired! Please resend your request to change your password.',
    emailConfirmationDescription:
      'Token expired! Please resend your request to confirm your email.',
    inviteConfirmationDescription:
      'Token expired! Please contact the sender of the email to resend the confirmation email.',
    resend: 'Resend request',
  },
  confirmedEmail: {
    header: 'Confirmed!',
    descriptioin: "Your email is already confirmed! You can now use the site's features.",
    btn: 'Go to the site',
  },
  table: {
    tabs: {
      active: 'Active',
      archive: 'Archive',
    },
    toolbar: {
      search: 'Search...',
    },
    selection: {
      selected: 'selected',
    },
    content: {
      noData: 'No data',
      noDataByFilters: 'No data matching the filter.',
      selectFilter: 'Select filter criteria.',
    },
    footer: {
      dense: 'Dense',
      rowsPerPage: 'Rows per page',
    },
  },
  menu: {
    event: {
      label: 'Event',
      event: {
        label: 'Event',
        create: 'Create',
        list: 'List',
        limit: 'Limit management',
        history: 'History',
      },
      statistics: {
        label: 'Statistics',
        report: 'Report email',
        sales: 'Sales',
        customizedStatistics: 'Customized statistics',
      },
      marketing: {
        label: 'Marketing',
        coupon: 'Coupon',
        statistics: 'Statistics',
      },
      finance: {
        label: 'Finance',
        settlement: 'Settlement',
      },
      guestTicket: {
        label: 'Guests',
        invite: 'Invite',
        list: 'Guestlist',
        limit: 'Limit management',
        settings: 'Settings',
      },
      boxoffice: {
        label: 'Box office',
        events: 'Events',
        settings: 'Settings',
      },
      newsletter: 'Newsletter',
    },
    entry: {
      label: 'Entry',
      entrySystem: {
        label: 'Entry system',
        list: 'List',
        edit: 'Edit',
        delete: 'Delete',
      },
    },
    ntak: {
      label: 'NTAK',
      attraction: {
        label: 'Attraction',
        registration: 'Registration',
        list: 'Log',
      },
      breadcrumb: {
        dashboard: 'Dashboard',
        event: 'Event',
        newEvent: 'New event',
        editEvent: 'Edit',
      },
    },
    accountSettings: {
      label: 'Account settings',
      account: {
        label: 'Account',
        account: 'My account',
        profile: 'Profile',
        companyData: 'Company data',
      },
      invitedUsers: {
        label: 'Users',
        invite: 'Invite',
        list: 'List',
        history: 'History',
      },
    },
    services: {
      label: 'Services',
      offers: 'Offer',
    },
  },
  emailConfirmationMsg:
    'Your email is not confirmed! Pleasse check your inbox to confirm your email! If there is no email ',
  resendEmail: {
    link: 'resend email!',
    successMsg: 'Verification email has been sent succesfully!',
    errorMsg: 'The email could not be sent. Please try again!',
    dialogTitle: 'Email verification',
  },
  searchBar: {
    placeholder: 'Search...',
    submit: 'Search',
  },
  organizerSelectLabel: 'Organizer',
  contactPopover: {
    contact: 'Your contact',
    copy: 'Copy',
    copied: 'Copied',
  },
  accountPopover: {
    horizontalNav: 'Menu position',
    home: 'Home',
    profile: 'Profile',
    settings: 'Settings',
    logout: 'Logout',
    errorMsg: {
      logoutFailed: 'Logout failed!',
    },
  },
  searchableSelect: {
    label: {
      event: 'Event',
      user: 'User',
    },
    all: 'All',
    search: 'Search',
    confirm: 'Save',
    cancel: 'Cancel',
  },
  timePeriod: {
    label: 'Time period',
    all: 'All',
    currentDay: 'Current day',
    currentWeek: 'Current week',
    previousWeek: 'Previous week',
    currentMonth: 'Current month',
    previousMonth: 'Previous month',
    currentYear: 'Current year',
    previousYear: 'Previous year',
    customized: 'Customized',
    from: 'From',
    to: 'To',
    errorMsg: {
      sales: 'Select a date between sales start date and sales end date/current date',
    },
  },
  pageTitles: {
    home: 'Home',
    login: 'Login',
    register: 'Register',
    passwordReset: 'Reset password',
    passwordNew: 'New password',
    eventCreate: 'Event: Create',
    eventEdit: 'Event: Edit',
    eventLimitManagement: 'Event: Limit management',
    eventHistory: 'Event: History',
    eventList: 'Event: List',
    statisticsSales: 'Statistics: Sales',
    statisticsDetails: 'Statistics: Details',
    statisticsReport: 'Statistics: Report',
    statisticsCustomized: 'Statistics: Customized statistics',
    marketingCoupon: 'Marketing: Coupon',
    marketingCouponCode: 'Marketing: Coupon code',
    marketingCouponStatistics: 'Marketing: Coupon statistics',
    accountProfile: 'Account: Profile',
    accountCompanyData: 'Account: Company data',
    userInvite: 'User: Invite a new user',
    userList: 'User: List',
    userHistory: 'User: History',
    offers: 'Offers',
    recommendation: 'Recommendation',
    invitationConfirm: 'Confirm invitation',
    invationtConfirmSucceeded: 'Succesfull confirmation',
    invitationConfirmFailed: 'Failed confirmation',
    emailConfirm: 'Email confirmation',
    emailConfirmed: 'Email confirmed',
    emailSent: 'Email sent',
    tokenExpired: 'Token expired',
    report: 'Report',
    ntakAttractionRegistration: 'NTAK Attraction: Registration',
    ntakAttractionList: 'NTAK Attraction: History',
    boxOfficeEvents: 'Box office: Events',
    boxOfficeOfflineTickets: 'Box office: Offline tickets',
    boxOfficeSettings: 'Box office: Settings',
    reportUnsubscribe: 'Unsibscribe',
    financeSettlement: 'Finance: Settlement',
    guestInvite: 'Guests: Invite',
    guestlist: 'Guests: List',
    guestStatistics: 'Guests: Statistics',
    guestDetails: 'Guests: Guest details',
    guestSettings: 'Guests: Settings',
    guestPreview: 'Tixa | Guests: Preview',
    replyToConfirmation: 'Reply-to confirmation',
    guestLimitManagement: 'Guests: Limit management',
    404: '404 Page Not Found',
    500: '404 Server Error',
  },
  notFoundRecord: {
    header: {
      event: 'Event not found',
      coupon: 'Coupon not found',
      customizedStatistics: 'Customized statistics not found',
      guestInvitation: 'Guest not found',
    },
    description:
      '{{ type }} with ID {{ id }} cannot be found at the organizer: {{ organizerName }}.',
  },
  page404: {
    title: 'Sorry, page not found',
    content:
      'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
    goHome: 'Go to Home',
  },
  page500: {
    title: '500 Internal Server Error',
    content: 'There was an error, please try again later.',
    goHome: 'Go to Home',
  },
  snackbar: {
    saveSucceeded: 'Saved successfully.',
    saveFailed: 'Failed to save.',
    deleteSucceeded: 'Deleted successfully.',
    deleteFailed: 'Failed to delete.',
    actionSucceeded: 'Action succeeded.',
    actionFailed: 'Action failed.',
    deletedRole:
      "Your '{{ role }}' role has been deleted! You will no longer access to the menu items related to this role.",
  },
  dialog: {
    autoLogout: {
      title: 'Attention!',
      content:
        "The session will end soon. If you wish to continue, click on the 'Stay logged in' button, otherwise click on the 'Log out' button to log out safely.",
      logout: 'Log out',
      stayLoggedIn: 'Stay logged in',
    },
    deletedOrganizer: {
      title: 'Deleted organizer',
      content: 'Your current organizer was deleted. Please choose another organizer from the list.',
      deletedAllOrganizerContent:
        "Your organizer was deleted. Contact the organiser or create your own organiser if you want to continue using the site's features.",
    },
    delete: {
      title: 'Delete confirmation',
      content: "You want to delete the '{{ name }}' {{ type }}. Are you sure?",
      action: {
        cancel: 'Cancel',
        delete: 'Delete',
      },
    },
  },
  errorMsg: {
    required: '{{ fieldNameEn }} is required.',
    invalidLoginData: 'Invalid login data',
    emailInUse: 'Email adress is already taken.',
    mustRegisterBeforeLogin:
      'You must register before login, based on the invitation email sent to you!',
    mustRegisterBasedInvitation: 'You must register based on the invitation email sent to you!',
    internalError: 'Internal server error',
    databaseError: 'Database error',
  },
};

export default en;
